import React from 'react'
import './Brainz.css'
import P5Wrapper from 'react-p5-wrapper'
import { BrainzApp } from './BrainzApp'
import Button from '../../components/Button/Button'

//<Button text='Toggle Training' click={() => setSimSpeed(simSpeed === 1 ? 40 : 1)}/>

const Plants: React.FC = () => {
  const [show, setShow] = React.useState(false)
  const [numCols, setNumCols] = React.useState(20)
  const [brushType, setBrushType] = React.useState('A')
  const [strength, setStrength] = React.useState(1)
  return (
    <div className='brainz'>
      {!show && <>
         Number of columns: <input type='number' step={1} placeholder="20" min={2} onChange={(e)=>{setNumCols(Number(e.target.value))}}/>
        <Button text='Start' click={() => setShow(true)}/>
        </>}
      {show && <>
      <P5Wrapper sketch={BrainzApp} numCols={numCols} brushType={brushType} strength={strength}/>
              <p>Press space to export your data file</p>
      <div className='controls' >
        <Button text='A' click={() => setBrushType('A')}/>
        <Button text='B' click={() => setBrushType('B')}/>
        <Button text='C' click={() => setBrushType('C')}/>
      </div>
      <div className='controls' >
        <Button text='D' click={() => setBrushType('D')}/>
        <Button text='E' click={() => setBrushType('E')}/>
        <Button text='F' click={() => setBrushType('F')}/>
      </div>
        <Button text='Show all' click={() => setBrushType('ALL')}/>
      Strength: <input type='number' step={0.01} placeholder="1" min={0} max={1} onChange={(e)=>{setStrength(Number(e.target.value))}}/>
      </>}
    </div>
  )
}

export default Plants
