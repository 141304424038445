import { isOutOfBounds } from '../simOperations'

const newPointFromVel = (pos, vel) => {
  return {
    x: pos.x + vel.x,
    y: pos.y + vel.y
  };
}

const getVel = (speed, angle) => {
  return {
    x: speed * Math.cos(angle),
    y: speed * Math.sin(angle)
  };
}

const getDistanceFromPivot = (pos, pivot_pos) => {
  return Math.sqrt( (pivot_pos.x - pos.x)**2 + (pivot_pos.y - pos.y)**2 );
}

const getAngleFromPivot = (pos, pivot_pos) => {
  return Math.atan2(pivot_pos.y - pos.y, pivot_pos.x - pos.x);
}

export default class Pendulum {
  constructor(pivot_pos, pos, length, color) {
    this.pivot_pos = pivot_pos;
    this.pos = pos;
    this.vel = {x: 0, y: 0}
    this.color = color;
    this.radius = 4;
    this.length = length;
  }

  update(gravity) {
    //if (getDistanceFromPivot(this.pos, this.pivot_pos) < this.length) {
      //this.vel.y += gravity;
    //}
    let angle = getAngleFromPivot(this.pos, this.pivot_pos);
    let forceX = 0.01*Math.sin(angle);
    let forceY = -0.01*Math.cos(angle);

    this.vel.x += forceX;
    this.vel.y += forceY;
    

    this.pos.x += this.vel.x;
    this.pos.y += this.vel.y;

  }

  draw(drawFunc) {
    drawFunc([0, 0, 0], this.pivot_pos.x, this.pivot_pos.y, this.radius);
    let dist = getDistanceFromPivot(this.pos, this.pivot_pos);
    drawFunc(dist >= this.length ? this.color : [0, 255, 0], this.pos.x, this.pos.y, this.radius);
  }
}
