const numGenes = 6

const isValidPos = (numCols, x, y) => {
  return x < numCols && x >= 0 && y < numCols && y >= 0
}

const letterToRegion = {
  'A': 0,
  'B': 1,
  'C': 2,
  'D': 3,
  'E': 4,
  'F': 5,
}

const letterToColor = {
  'A': [255, 0, 0],
  'B': [0, 255, 0],
  'C': [0, 0, 255],
  'D': [255, 255, 0],
  'E': [0, 255, 255],
  'F': [255, 0, 255],
  'ALL': [255, 255, 255]
}

export const BrainzApp = (p5) => {
  // props
  let numCols = 100
  let brushType
  let timer
  let strength

  let windowDimensions = {
    width: Math.min(p5.windowWidth / 1.3, p5.windowHeight / 1.3),
    height: Math.min(p5.windowWidth / 1.3, p5.windowHeight / 1.3),
  }
  let cellSize = windowDimensions.width / numCols
  let mousePos = { x: 0, y: 0 }

  let pixelsHorizontal
  let cellWidth
  let grid

  p5.setup = () => {
    timer = 0
    p5.pixelDensity(1)
    p5.createCanvas(windowDimensions.width, windowDimensions.height)
    p5.frameRate(60)
    p5.loadPixels()
    // number of pixels in one row
    pixelsHorizontal = Math.sqrt(p5.pixels.length / 4)
    //pixelsHorizontal = windowDimensions.width
    cellWidth = pixelsHorizontal / numCols
    strength = 1
    grid = []
    for (let i = 0; i < numCols**2; i++) {
      grid.push([])
      for (let j = 0; j < numGenes; j++) {
        grid[i].push([0, 0, 0])
      }
    }
  }

  const idxToPos = (i) => {
    return [i % numCols, Math.floor(i / numCols)]
  }

  const posToIdx = (x, y) => {
    return y * numCols + x
  }

  p5.windowResized = () => {
    windowDimensions = {
      width: Math.min(p5.windowWidth / 1.3, p5.windowHeight / 1.3),
      height: Math.min(p5.windowWidth / 1.3, p5.windowHeight / 1.3),
    }
    p5.resizeCanvas(windowDimensions.width, windowDimensions.height)
    cellSize = windowDimensions.width / numCols
    pixelsHorizontal = Math.sqrt(p5.pixels.length / 4)
    cellWidth = pixelsHorizontal / numCols
  }

  p5.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    // remake grid if number of columns have changed
    if (numCols !== props.numCols) {
        grid = []
        for (let i = 0; i < props.numCols**2; i++) {
          grid.push([])
          for (let j = 0; j < numGenes; j++) {
            grid[i].push([0, 0, 0])
          }
        }
    }
    numCols = props.numCols
    brushType = props.brushType
    cellSize = windowDimensions.width / numCols
    cellWidth = pixelsHorizontal / numCols
    strength = props.strength
  }

  const onMousePress = () => {
    if (!isValidPos(numCols, mousePos.x, mousePos.y)) return
    if (brushType === 'ALL') return
    let color = letterToColor[brushType]
    color = color.map(i => i * strength)
    grid[posToIdx(mousePos.x, mousePos.y)][letterToRegion[brushType]] = color
    //switch (brushType) {
      //case 'A':
        //console.log('A')
        ////newCell = new Sand(clickedPos.x, clickedPos.y)
        //break
      //default:
        //console.log('something else')
        //break
    //}
    //grid[clickedPos.x][clickedPos.y] = newCell
  }

  const drawPixel = (color, xpos, ypos) => {
    let firstIndex = Math.floor(pixelsHorizontal*4*Math.floor(cellWidth*ypos) + 4*(Math.floor(cellWidth*xpos)))
    let index
    for (let i = 0; i < cellWidth; i++) {
      for (let j = 0; j < cellWidth; j++) {
        index = firstIndex + i*pixelsHorizontal*4 + j*4
        //index = firstIndex + i*cellWidth
        p5.pixels[index] = color[0]
        p5.pixels[index + 1] = color[1]
        p5.pixels[index + 2] = color[2]
        // default to max alpha
        p5.pixels[index + 3] = 255
      }
    }
  }

  p5.draw = () => {
    p5.background(0, 0, 0)

    //if (timer % 3 == 0) {console.log(Math.cos(timer/dayLength - Math.PI/2))}
    
    timer += 1

    if (p5.mouseIsPressed) {
      onMousePress()
    }
    // SUN
    p5.loadPixels()
    //for (let i = 0; i < 
    let color
    for (let i = 0; i < numCols; i++) {
      for (let j = 0; j < numCols; j++) {
        if (brushType === 'ALL') {
          for (let k = 0; k < numGenes; k++) {
            color = grid[posToIdx(i, j)][k]
            // ignore black values --- longer term fix 
            // to allow for NULL values or similar would be nice
            if (timer % 60 === 0) {
            }
            if (!(color[0] === 0 && color[1] === 0 && color[2] === 0)) {
              drawPixel(color, i, j)
            }
          }
        } else {
          color = grid[posToIdx(i, j)][letterToRegion[brushType]]
          if (!(color[0] === 0 && color[1] === 0 && color[2] === 0)) {
            drawPixel(color, i, j)
          }
        }
        //grid[]
      }
       //draw every grid element from the current layer
       //using letterToRegion[current_layer]  e.g current_layer = 'A'
    }

    p5.updatePixels()

    mousePos = {
      x: Math.max(Math.floor(p5.mouseX / cellSize)),
      y: Math.max(Math.floor(p5.mouseY / cellSize)),
    }

    if (isValidPos(numCols, mousePos.x, mousePos.y)) {
      p5.stroke(letterToColor[brushType])
      p5.noFill()
      p5.rect(mousePos.x * cellSize, mousePos.y * cellSize, cellSize, cellSize)
    }
  }

  p5.keyPressed = (a) => {
    console.log(a.code)
    if (a.code === "Space") {
      console.log("EXPORTING DATA")
      console.log(grid)
      console.log("EXPORTING DATA")
      let exportData = String(numCols) + "\n"
      let ind
      for (let i = numCols - 1; i >= 0; i--) {
          for (let k = 0; k < numCols; k++) {
            ind = posToIdx(k, i)
            for (let j = 0; j < numGenes; j++) {
              exportData += String(grid[ind][j][0]) + "-" + String(grid[ind][j][1]) + "-" + String(grid[ind][j][2]) + ","
            }
            exportData += "\n"
          }
      }
      let blob = new Blob([exportData], {type: 'text/plain'})
      var downloadLink = document.createElement("a");
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'data.txt';

      // document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }
} 
