import Pendulum from './Classes/Pendulum'
import { isOutOfBounds } from './simOperations'
export const PendulumSim = (p5) => {
  // defaults
  let WIDTH = 512;
  let NUM_PENDULUMS = 1;

  const getWindowDimensions = () => {
    return {
      //width: Math.min(p5.windowWidth * CANVAS_SCALE_FACTOR,
        //p5.windowHeight * CANVAS_SCALE_FACTOR),
      //height: Math.min(p5.windowWidth * CANVAS_SCALE_FACTOR,
        //p5.windowHeight * CANVAS_SCALE_FACTOR),
      width: WIDTH,
      height: WIDTH
    }
  }
  let windowDimensions = getWindowDimensions()

  let pendulums = []
  let dt = 0;

  let pixelsHorizontal

  p5.setup = (props) => {
    windowDimensions = getWindowDimensions()

    p5.background(70, 70, 70)
    p5.pixelDensity(1)
    p5.loadPixels()
    p5.background(70, 70, 70)
    p5.createCanvas(windowDimensions.width, windowDimensions.height)
    //pixelsHorizontal = Math.sqrt(p5.pixels.length / 4)
    pixelsHorizontal = Math.floor(windowDimensions.width)
    p5.frameRate(60)
    for (let i = 0; i < NUM_PENDULUMS; i++) {
       pendulums.push(new Pendulum(
         { x: windowDimensions.width / 2, y: windowDimensions.height / 3},
         { x: windowDimensions.width / 2 + 30, y: windowDimensions.height / 3},
         100, [255, 0, 0]));
       pendulums.push(new Pendulum(
         { x: windowDimensions.width / 2, y: windowDimensions.height / 3},
         { x: windowDimensions.width / 2 + 0, y: windowDimensions.height / 3},
         100, [255, 0, 0]));
    }

  }

  //p5.myCustomRedrawAccordingToNewPropsHandler = (props) => {
    //WIDTH = props.width
    //windowDimensions = getWindowDimensions()
//
    //p5.background(0, 0, 0);
    //p5.pixelDensity(1);
    //p5.loadPixels(); p5.background(70, 70, 70);
    //p5.createCanvas(windowDimensions.width, windowDimensions.height);
    //pixelsHorizontal = Math.floor(windowDimensions.width);
    //p5.frameRate(60);
    //
    //for (let i = 0; i < NUM_PENDULUMS; i++) {
    //}
  //}


  const getPixelIndex = (xpos, ypos) => {
    return Math.floor(pixelsHorizontal*4*Math.floor(ypos) + 4*(Math.floor(xpos)))
  }

  const drawPixel = (color, xpos, ypos, drawWidth) => {
    let firstIndex = getPixelIndex(xpos, ypos)
    let index
    for (let i = 0; i < drawWidth; i++) {
      for (let j = 0; j < drawWidth; j++) {
        index = firstIndex + i*pixelsHorizontal*4 + j*4
        //index = firstIndex + i*drawWidth
        p5.pixels[index] = color[0]
        p5.pixels[index + 1] = color[1]
        p5.pixels[index + 2] = color[2]
        // default to max alpha
        p5.pixels[index + 3] = 255
      }
    }
  }


  p5.draw = () => {
    dt++;
    p5.background(70, 70, 70)

    p5.loadPixels()
    for (let pendulum of pendulums) {
      pendulum.update(0.1);
      pendulum.draw(drawPixel);
    }
    p5.updatePixels()
  }
  
  p5.keyPressed = (a) => {
    //console.log(a.code)
  }
}
