import React from 'react'
import './Home.css'
const Home: React.FC = () => {
  return (
    <div className="Home">
      <h1>Hey, I'm Ben.</h1>
      <div className="Bio">
        <h2>About me &#127925;</h2>
        <p>
          Thanks for checking out my website! 
          <br />
          I'm an undergraduate student from Sydney, Australia. Currently, I am pursuing a
          Bachelor of <i>Advanced Computing and Science</i> at the University of
          Sydney, majoring in physics and computer science. This is primarily my
          page for hosting little webapps and linking to my projects, but I'm 
          always happy to get in contact about what I'm working on!
      </p>
        <p>
            In my free time I enjoy programming, music, and physics. 
            I was lucky enough to be trained in classical piano from 
            a young age, and have in recent years started composing as a hobby.
        </p>
        <p>
            In the past I've worked as a <i>Junior Software Developer</i> doing
      full stack web development at <i>Envisage Software Solutions</i>. Currently,
       I have the privilege of working as a research assistant at
          Macquarie University with the Burgarth group, focusing on quantum control,
          and performing some experiments on the IBM quantum computers.
        </p>
        <h2>Technical Skills &#128295;</h2>
        <h3>Programming</h3>
        <h4>Preferred Languages</h4>
        C, Java, Python, JavaScript, TypeScript, R, Lua.
        <br />
        <br />
        <h4>Frameworks / Tools</h4>
        Unix, nginx, React, p5.js, node.js, Firebase, Google Cloud.
        <br />
        <br />
        <p>
          My preferred environment is a system running Arch Linux. I
          like to use vim as my primary editor when possible. Most of my general
          configuration can be found{' '}
          <a href="https://github.com/enbr55/dotfiles">here</a>.
        </p>
        <h2>Projects &#128161;</h2>
        <p>
          I'm working on new projects all the time. I always appreciate suggestions
          on fun things to develop. Some of the stuff that I'm working on, or
          have worked on, can be found in the <a href="/projects">projects</a>{' '}
          section.
        </p>
        <p>
          Some of my public work can be found on <a href="https://github.com/EnBr55">my GitHub</a>.
        </p>
        <h2>Contact &#128075;</h2>
        <p>I'd love to get in touch :-)</p>
        <p>
          <b><i style={{color: '#604'}}>Je ne suis pas avocat.</i></b> Arrêtez de me téléphoner pour des conseils légaux s’il vous plaît.
          <br />
          Email: <a href="mailto:ben@benbraham.com">ben@benbraham.com</a>
          <br />
          Mobile: +61 481 394 909
        </p>
      </div>
    </div>
  )
}

export default Home
