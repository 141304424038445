import React from 'react'
import './Pendulums.css'
import P5Wrapper from 'react-p5-wrapper'
import { PendulumSim } from './PendulumSim'
import Button from '../../components/Button/Button'

const Pendulums: React.FC = () => {
  return (
    <div className='snakes'>
      <P5Wrapper style={{width: '50%'}}
      sketch={PendulumSim}/>
    </div>
  )
}

export default Pendulums
